.employee_data_header {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 1em;
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  outline: 1px solid;
  border: 1px solid;
}
.new__width {
  width: 90%;
}
.employee_tr > th {
  font-weight: 500;
  width: 170px;
  text-align: left;
  font-size: 14px;
}
.Violation_btn {
  background-color: #002d62;
  color: white;
  cursor: pointer;
}
.Violation_btn:hover,
.Violation_btn:focus {
  background-color: #07c;
}

.Violation_btn:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
}

.Violation_btn:active {
  background-color: #0064bd;
  box-shadow: none;
}
.thead {
  background-color: #002d62;
  height: 50px;
  border: none;
  color: white;
}
.employee_tr > th {
  font-weight: 500;
  width: 170px;
  text-align: left;
}

.employee_tbody {
  background-color: white;
  text-align: left;
}
.employee_tbody > tr {
  border-top: 1px solid gray;
  font-size: 14px;
}

.employee_tbody_tr {
  font-size: 14px;
  border-bottom: 1px solid rgb(58, 56, 54);
  border-bottom: 1px solid rgb(79, 77, 74);
  border: 1px solid rgb(103, 103, 103);
  border-collapse: collapse;
}
.employee_tbody_tr:last-child {
  border-bottom: none;
}

.View {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 10px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  margin: 3px;
}

.View:hover,
.View:focus {
  background-color: rgb(2, 21, 34);
}

.View:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
}

.View:active {
  background-color: #031423;
  box-shadow: none;
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 25px;
}
.enter_no {
  border: none;
  border-bottom: 1px solid black;
}
.end {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px 15px;
  font-size: 2rem;
  /* text-decoration: underline;
  text-decoration-thickness: 2.3px;
  text-decoration-color: #07c;
  text-decoration-style: double  */
}
.cliploader {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.align__voilation__reset__get {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
@media only screen and (max-width: 600px) {
  .scroll {
    overflow-x: scroll;
    width: 100%;
    /* padding: 10px 10px 10px 10px */
  }
  .end {
    padding: 20px 0px;
    flex-direction: column;
  }
  .align__voilation__reset__get {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-left: 30px;
  }
}
.table__video__app {
  overflow: auto;
  margin-top: 20px;
  text-transform: uppercase;
}
.main__admin input {
  width: 300px;
  height: 35px;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  padding-left: 5px;
}
.custom-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-collapse: collapse;
}
.header-cell {
  border-radius: 4px;
}
.header-cell td {
  background-color: #2f2f5a;
  color: white;
  padding: 10px;
  border: 1px solid #2f2f5a;
}

.table-row {
  height: 32px;
  margin: 5px 0;
  padding: 10px;
  border-bottom: 0.3px solid #2f2f5a; /* Add bottom border between rows */
}

.custom-table td {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 4px 16px 4px 40px;
}
.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  border-radius: 5px; /* Apply border-radius to the table */
}

.custom-table td,
.custom-table th {
  padding: 5px 20px;
  border-top: 1px solid gray;
}

.custom-table tr:first-child th {
  border-top: none;
}

.custom-table tr:last-child td {
  border-bottom: none;
}

.searchhhhhh {
  display: flex;
  margin-left: 30px;

  margin: 10px 0px;
  gap: 10px;
  font-size: 18px;
}
.searchhhhhh input {
  width: 300px;

  padding-right: 20px;
  padding-left: 5px;
  border: 1px solid;
  border-radius: 5px;
}
.search__icon {
  position: relative;
  right: 30px;
  background-color: #fff;
  height: 22px;
  top: 3px;
}
.button_style {
  padding: 5px 25px;
  background-color: #2f2f5a;
  color: white;
  width: "110px";
  border-radius: 3px;
  height: fit-content;
}
