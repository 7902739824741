.parent_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #dcdcdc;
}
.footer {
  /* position: fixed; */
  height: 50px;
  /* background-color: red; */
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 50vh;
}

.child_div1 {
  font-size: medium;
  width: 50%;
  margin-bottom: 25px;
  color: black;
}

.child_div2 {
  width: 50%;
  margin-top: 30px;
  padding: 3px;
  color: black;
}

.copyright {
  text-align: center;
  background-color: rgb(122, 117, 117);
  padding: 5px;
  color: white;
}
.details {
  margin-bottom: 8px;
}
.para {
  margin-top: 15px;
}
.link {
  color: blue;
}
.stamp {
  width: 150px;
  position: absolute;
  margin-left: 120px;
  margin-top: -60px;
}

@media only screen and (max-width: 600px) {
  .footer {
    width: 100%;
    height: 100%;
  }
  .parent_div {
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: #dcdcdc;
  }
  .child_div2 {
    width: 100%;
    margin-top: 0px;
    padding: 3px;
    color: black;
  }
  .stamp {
    width: 83px;
    position: absolute;
    margin-left: 113px;
    margin-top: -36px;
  }
  .child_div1 {
    width: 100%;
  }
}
