.filter_for_attendance {
  display: flex;

  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.filter_for_attendance label {
  width: 60px;
}
.dept label {
  width: 120px;
}
.end_date {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filters_for_Search {
  height: 30px;
  width: 180px;
  color: black;
  border: 1px solid gray;
}
.filters_for_Department {
  height: 30px;
  min-width: 180px;
  border: 1px solid gray;
}
.filters_for_Designation {
  height: 30px;
  width: 180px;
  border: 1px solid gray;
}
.filters_for_Exited {
  height: 30px;
  width: 80px;
  border: 1px solid gray;
}
.filters_for_Date {
  height: 30px;
  width: 100px;
  border: 1px solid rgb(83, 26, 26);
}

.filters_for_emp_attendance {
  height: 24px;
  border-radius: 4px;
  border: "1.5px solid";
}
.table_padding {
  padding: "20px";
}
.recors_att {
  width: fit-content;
  padding-left: 20px;
}
.record_attendance {
  border-radius: 2px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;
  cursor: pointer;
  display: flex;
  /* width:130px; */
  gap: 10px;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 10px;
  outline: none;
  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 25px;
}
.filters_for_emp_attendance {
  width: 130px;
}
.align__emp__reset {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  width: 37%;
}
.record_attendance {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.align__emp__reset {
  font-size: 14px;
  margin: 5px 0px;
}
@media only screen and (max-width: 600px) {
  .filter_for_attendance {
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 20px;
    gap: 10px;
  }
  .filter_for_attendance p {
    width: 100px;
  }
  .end_date {
    display: flex;
    gap: 50px;
  }
  .start_date_emp {
    display: flex;
    gap: 48px;
  }
  .filters_for_emp_attendance {
    width: 132px;
  }
  .search_by_empname {
    display: flex;
    gap: 47px;
  }
  .table_padding {
    padding: "0px";
  }
  .table_align {
    padding: 0px 10px;
  }
  .filters_for_Exited,
  .filters_for_Date {
    min-width: 180px;
  }

  .for_attendance_submit {
    justify-content: left;
  }
  .empcode_select {
    width: 0px;
  }
  .attendance_btn {
    padding: 45px 0px 0px 11px;
  }
  .align__emp__reset {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    width: 100%;
  }
}
