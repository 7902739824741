.performance_of_employee {
  text-align: center;
  font-size: 2rem;
}
.performance_detials {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 40px;
}
/* .performance_detialss{
    display: flex;
    text-align: center;
    justify-content: center;
    gap:120px
} */
.performance_detials > p > label {
  text-align: center;
  margin-top: 10px;
  width: 200px;
}
.performance_detials > p {
  text-align: center;
  display: flex;
  gap: 6px;
}
.get_data_performance {
  display: flex;
  justify-content: space-around;
  margin-left: 550px;
}
.get_data_performance_detials {
  display: flex;
  justify-content: space-around;
  margin-left: 700px;
}

.get_data {
  width: fit-content;
  height: 30px;
  border-radius: 2px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;
  margin: 10px;
  outline: none;
  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
  color: white;
}

.Analytics_btns {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.Analytics_btns_button {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  height: 40px;
  width: 105%;
  margin: 0;
  outline: none;
  padding: 8px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}
.heading {
  text-align: center;
  padding-right: 190px;
  padding-top: "29px";
}
.heading span {
  font-weight: 500px;
  font-size: 18px;
}
.select_dept {
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px;
}
.graph_filter_date {
  justify-content: center;
  display: flex;
  gap: 20px;
}

.graph_filter_date_performace {
  justify-content: center;
  display: flex;
  gap: 150px;
}
.Graph__date {
  height: 25px;
  width: 150px;
  border-radius: 4px;
}
.datewise_filter {
  display: flex;
  gap: 30px;
}
/* .h4 {
    color: rgb(47, 47, 142);
    font-family: Arial, sans-serif;
    font-size: 24px;
    text-align: center;
    text-decoration: underline;
  } */
.h4 {
  position: relative;
  padding: 0;
  margin: 0;
  /* font-family: "Raleway", sans-serif; */
  font-weight: 300;
  font-size: 19px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.h4 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
.h4 em {
  font-style: normal;
  font-weight: 600;
}
.one h4 {
  text-align: center;
  /* text-transform: uppercase; */
  padding-bottom: 5px;
}
.one h4:before {
  width: 155px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 46%;
  margin-left: -14px;
  background-color: #302475;
}
.one h4:after {
  width: 59%;
  height: 1.5px;
  display: block;
  content: "";
  position: relative;
  margin-top: 8px;
  left: 24%;
  margin-left: -50px;
  background-color: #302475;
}
.performace__table {
  border-collapse: collapse;
  margin: 30px;
  width: 450px;
}

.performace__table > tr {
  padding: 10px;
  border-radius: 5px;
  transition: all ease-in-out 0.5s;
}
.performace__table > tr:hover {
  background-color: rgb(230, 231, 232);
}
.performace__table > tr:last-child:hover {
  background-color: rgb(255, 255, 255);
}
.reset__performace {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.performace__table > tr > td:first-child {
  width: 160px;
}
.performace__table > tr > td {
  padding: 10px;
}
.performace__table > tr > td > input {
  height: 35px;
}
.analytics__table {
  border-collapse: collapse;
  margin: 10px 20px;
  width: 420px;
  height: fit-content;
}

.analytics__table > tr {
  padding: 10px;
  border-radius: 5px;
  transition: all ease-in-out 0.5s;
}
.analytics__table > tr:hover {
  background-color: rgb(230, 231, 232);
}
.analytics__table > tr:last-child:hover {
  background-color: rgb(255, 255, 255);
}
.analytics__table > tr > td:first-child {
  width: 180px;
}
.analytics__table > tr > td {
  padding: 10px;
}
.analytics__table > tr > td > input {
  height: 35px;
}
.heading__enter {
  color: red;
  margin-left: 30px;
  margin-top: 40px;
}
.for__perfomace__sort__chart {
  display: flex;
  justify-content: space-between;
  height: 400px;
}
.heading__one__analytics {
  margin: 30px 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.heading__one__active {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-right: 20px;
  transition: all 1s ease;
}
.end__analytics {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.none__defoult {
  display: block;
  transition: all 3s ease;
}
.align__select__td > p {
  margin-bottom: 10px;
}
.chartContainerStyle {
  width: 700px;
  height: 700px;
}

@media only screen and (max-width: 600px) {
  .performance_detials {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 40px;
  }
  .get_data_performance {
    display: flex;
    margin-left: 20px;
    justify-content: start;
  }
  .performance_detials {
    padding: 10px;
    gap: 0px;
  }
  /* .bar_graph {
    overflow-x: scroll;
  } */
  .heading {
    text-align: center;
    padding-right: 0px;
    padding-top: "20px";
  }
  .graph_filter_date {
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
  .graph_filter_date_performace {
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
  .datewise_filter {
    display: flex;
    gap: 55px;
  }
  .get_data_performance_detials {
    display: flex;
    justify-content: left;
    margin-left: 0px;
  }
  .graph_filter_date_performace label {
    justify-content: center;
    display: flex;
    gap: 20px;
    width: 151px;
  }
  .performace__table {
    width: 90%;
    margin: 10px;
  }
  .bar_graph {
    overflow: auto;
  }
  .for__perfomace__sort__chart {
    flex-direction: column;
    height: 100%;
  }
  .heading__enter__fiter {
    margin-left: 20px !important;
  }
  .end__analytics {
    flex-direction: column;
    align-items: flex-start;
  }
  .heading__one__active {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 40px;
  }

  .analytics__table > tr > td {
    padding-bottom: 50px;
  }
  .analytics__table {
    width: 300px;
  }

  .chartContainerStyle {
    width: 450px;
    height: 700px;
  }
}
