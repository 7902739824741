.View_voliations {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #e8eafe;
  padding: 20px 0px;
  border-radius: 9px;
  gap: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.View_voliations_filter_dates {
  display: flex;
  justify-content: space-around;
  padding: 18px 0px 20px;
}
.box_padding {
  padding: 20px;
}
.btn_alignment {
  padding: 15px 0px 20px;
}
.inputs {
  border: 1px solid;
  height: 28px;
  border-radius: 4px;
}
.Violations {
  padding: 0px 20px;
}
.media_filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.media_filter {
  display: flex;
  gap: 19px;
}
.media_filter_end_date {
  display: flex;
  gap: 29px;
}
.go_back_btn {
  padding-right: 5%;
  text-align: right;
  padding-bottom: 20px;
}
.go_back_btn2 {
  padding-right: 220px;
  text-align: right;
  padding-bottom: 20px;
}
.Date_filter_violation {
  display: flex;
  padding-left: 80px;
  gap: 92px;
}
.filters_for_violation {
  display: flex;
  justify-content: space-around;
}
.View {
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  .View_voliations {
    flex-direction: column;
    gap: 20px;
  }
  .media_filters {
    display: flex;

    justify-content: unset;
    flex-direction: column;
    align-items: start;
  }
  .go_back_btn2 {
    padding: 10px;
  }
  .go_back_btn {
    padding: 20px;
  }
  .Date_filter_violation {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    gap: 20px;
  }
  .Date_filter_violation label {
    width: 125px;
  }
  .filters_for_violation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
  }
  .filters_for_violation label {
    width: 250px;
  }
  .media_filter_end_date {
    display: flex;
    gap: 21px;
  }
}
