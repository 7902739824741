.pic__align_login {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.picture__login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  mix-blend-mode: multiply;
}
.picture__login > h2 {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 30px;
  color: #1b0c70;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.mode__of__Login {
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
}
.mode__of__Login > select {
  height: 30px;
  width: 150px;
}
.form__mail__pass > p {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.form__mail__pass > input {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  padding: 0px 10px;
}
.forgot__show__password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}
.forgot__show__password > button {
  border: none;
  background-color: inherit;
  text-decoration: underline;
  font-weight: 500;
}
.show__password__align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 366px;
  padding: 40px;

  /* background: linear-gradient(#99cee3, #907ac0); */
  background-color: #d2e3ff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 20px;
  padding: 0;
  color: #1b0c70;
  text-align: center;
}
.mode_of_login {
  margin-bottom: 34px;
  color: #1b0c70;
  display: flex;
  gap: 20px;
}
.select_loginas {
  width: 140px;
  border: none;
  border-bottom: 1px solid;
  border-radius: 3px;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 91%;
  padding: 6px 10px;
  font-size: 16px;
  color: #1b0c70;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  border-radius: 4px;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 17px;
  color: #1b0c70;
}

.login-box .user-box input ~ label {
  top: -37px;
  left: 0;
}
.login-box
  .user-box
  input:focus
  ~ label
  .login-box
  .user-box
  input:valid
  ~ label {
  top: -30px;
  left: 0;
  font-size: 20px;
  padding: 2px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 20px;
  letter-spacing: 4px;
  border: solid 0.1px;
  border-radius: 7px;
}

.login-box a:hover {
  /* color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 1px #03e9f4, 0 0 0px #03e9f4, 0 0 3px #03e9f4,
    0 0 42px #03e9f4; */
  background-color: #0d0733;
  color: #fff;
}
.login-box a {
  /* color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 1px #03e9f4, 0 0 0px #03e9f4, 0 0 3px #03e9f4,
    0 0 42px #03e9f4; */
  background-color: #1b0c70;
  color: #fff;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1):hover {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child:hover {
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3):hover {
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4):hover {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
.show__password {
  color: #1b0c70;
  font-size: 14px;
}
.forword {
  margin-left: 19px;
  margin-top: 5px;
  border: none;
  background-color: unset;
}
.errmsg {
  color: rgb(255, 0, 0);
  font-weight: 500;
  text-align: center;
  margin: 10px;
}
.offscreen {
  display: none;
}
.Loaders {
  text-align: center;
}

/*  */
.fpo {
  position: relative;
  margin: 25% auto;
  display: block;
}
.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}
.block__login {
  display: block;
  width: 591px;
  height: 16px;
  color: black;
}

.block2 {
  width: 78px;
  height: 8px;
  margin-bottom: 8px;
}
.block3 {
  width: 131px;
  height: 8px;
  margin-bottom: 16px;
}
.circle__login {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  float: right;
}
#card {
  box-sizing: border-box;
  width: 635px;
  background: #fff;
  position: relative;
  margin: auto;
  top: 25%;
}
.card-image {
  box-sizing: border-box;
  display: block;
  width: 635px;
  height: 120px;
  background: #fafafa;
  padding: 16px;
}
.card-content {
  clear: both;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
}
#imagePreview {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
#imagePreview_2 {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media only screen and (max-width: 600px) {
  /* .login-box {
    top: 40%;
  } */
  .pic__align_login {
    flex-direction: column;
    height: 130vh;
    justify-content: center;
    align-items: center;
    /* width: 95%; */
  }
  .login-box {
    width: 90%;
  }
  #card {
    box-sizing: border-box;
    width: 335px;
    background: #fff;
    position: relative;
    margin: auto;
    top: 0%;
  }
  .block__login {
    display: block;
    width: 301px;
    height: 16px;
    color: black;
  }
  .card-image {
    box-sizing: border-box;
    display: block;
    width: 335px;
    height: 120px;
    background: #fafafa;
    padding: 16px;
  }
}
