.btn_link {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}
.btn_link img {
  width: 50px;
  height: 50px;
}
.btn_link a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  background-color: rgb(234, 245, 252);
  border-radius: 5px;
  padding: 5px;
  width: 150px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  -webkit-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.75);

  transition: 0.5s ease-in-out;
}
.btn_link a:hover {
  background-color: rgb(212, 234, 248);

  -webkit-box-shadow: 10px 10px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px -2px rgba(0, 0, 0, 0.75);
}
