.profile-icon {
	text-align: right;
	cursor: pointer;
	font-size: 34px;
    color: royalblue;
    /* justify-content: end; */
    /* padding: 9px 9px 0px 9px */
  }
  
  .profile-icon img {
	width: 32px;
	height: 32px;
  }
  
  .logout-option {
	position: absolute;
    /* top: 0px; */
    right: 0px;
    background-color: #ffffff;
    border-radius: 4px;
    border: solid #c2c2f8;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
    padding: 1px 14px 2px 9px;
    z-index: 1;
	font-size: 21px;
  }
  
  .logout-option ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
  }
  
  .logout-option li {
	/* padding: 8px; */
	cursor: pointer;
	text-align: left;
  }
  
  .Profile_details{
	display: flex;
    justify-content: end;
    padding: 7px;
    margin: 2px 17px;
  }
  .Profile_details1{
	display: flex;
    justify-content: end;
    padding: 7px;
    margin: 2px 17px;
	margin-left: 70px;
  }
 
 