.New_Violation {
  text-align: center;
  height: 100vh;
}
.Violation_form {
  background-color: #d0daff;
  border-radius: 8px;
  width: 600px;
}

.new_violation_form {
  text-align: center;
  display: flex;
  justify-content: center;
}
.Violation_form > div {
  display: flex;
  padding: 12px;

  align-items: center;
}
.Violation_form > div > label {
  width: 170px;
  text-align: left;
  margin-left: 20px;
}

.new_Violation_date {
  height: 31px;
  /* border: none; */
  /* border-bottom: 1px solid; */
  border-radius: 4px;
  width: 299px;
}
.New_Violation_save_btn {
  text-align: -webkit-right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
}
.width__select__voilation {
  width: 300px;
}
.Violations_title {
  display: flex;
  padding: 1px 20px;
}
.Note_1 {
  min-width: 300px;
  max-width: 300px;
  min-height: 81px;
  max-height: 81px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
@media only screen and (max-width: 600px) {
  .Violation_form {
    padding: 10px;
    width: 400px;
  }
  .Violations_title {
    display: flex;
    flex-direction: column;
  }
  .Violations_title > p {
    padding-left: 20px;
  }

  .new_violation_form {
    text-align: center;
    /* display: none; */
    /* justify-content: center; */
  }
  .Violation_form {
    background-color: #d0daff;
    border-radius: 8px;
  }
  .Note_1 {
    min-width: 202px;
    max-width: 202px;
    min-height: 81px;
    max-height: 81px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .Violation_form > div > label {
    width: 222px;
    text-align: left;
    margin-left: 0px;
  }
  .New_Violation_save_btn {
    text-align: -webkit-right;
    /* display: flex; */
    margin-left: 0px;
  }
}
