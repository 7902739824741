.banner__align {
  display: flex;
  justify-content: center;
}

.client__datas > div {
  background-color: white;
  height: 40px;
  padding: 0px 10px;
  border-radius: 5px;
  margin: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Dates__filter__client > div {
  background-color: white;
  padding: 7px 10px;
  border-radius: 5px;
  margin: 10px;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.client__datas > div > p {
  display: inline-block;
  color: red;
  font-weight: 500;
}

.dates__select > div > input,
.dates__select > div > select {
  height: 25px;
  width: 120px;
}

.employee__card__client {
  display: flex;
  border-radius: 2px;
  border-radius: 26px;
  margin: 30px;
  justify-content: center;
}

/* CustomAlert.css */
/* Overlay.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 99; /* Ensure the overlay is above other content */
}

.custom-alert {
  position: fixed;
  border: 1px solid;
  top: 50%;
  left: 50%;
  background: rgb(255, 255, 255);
  z-index: 100;
  padding: 20px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px,
    rgba(255, 255, 255, 0.3) 0px 30px 60px -30px,
    rgba(255, 255, 255, 0.35) 0px -2px 6px 0px inset;
}

.custom-alert p {
  margin: 0;
  font-size: 24px;
}
.align__button {
  display: flex;
  justify-content: end;
}
.custom-alert button {
  background-color: rgb(3, 3, 35);
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: bold;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
}

@media (max-width: 660px) {
  .custom-alert {
    width: 95%;
  }
  .voilation__table {
    width: 200px;
    padding: 10px 0px;
  }
  .trip__date__table {
    width: 150px;
    padding: 10px 0px;
  }
  .voilation__jrno {
    width: 120px;
  }
}

/* Keyframes */
.button__Style {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes example {
  from {
    position: fixed;
    top: -100%;
    left: 50%;

    z-index: 100;
    padding: 20px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px,
      rgba(255, 255, 255, 0.3) 0px 30px 60px -30px,
      rgba(255, 255, 255, 0.35) 0px -2px 6px 0px inset;
  }
  to {
    position: fixed;
    top: 50%;
    left: 50%;

    z-index: 100;
    padding: 20px;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

@media only screen and (max-width: 720px) {
  .client__datas > div {
    flex-direction: column;
    height: auto;
    padding: 5px 3px;
    align-items: start;
  }
}
