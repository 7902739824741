.line-1 {
  height: 1px;
  background: black;
  width: 510px;
}
.Employee_detials {
  line-height: 1.5px;
}

.Employee_details {
  align-content: center;
  text-align: center;
}
.second_divission {
  display: flex;
  justify-content: center;
  padding: 20px;

  gap: 20px;

  background-color: white;
}

.second_info {
  text-align: left;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
}

.second_info2 {
  text-align: left;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1rem;
}
.employee_card {
  display: flex;

  border-radius: 2px;
  border-radius: 26px;
}
.image_section {
  text-align: start;
  padding: 20px;
  line-height: 30px;
  background-color: #bae9e9;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.align > div {
  display: flex;

  justify-content: end;
}
.align {
  display: flex;
  gap: 18px;
  padding: 15px;
}
.align2 {
  display: flex;
  gap: 18px;
  padding: 15px;
}
.align2 > div {
  justify-content: end;
  display: flex;
}

b {
  display: table-cell;
}
.align b {
  display: inline-block;
  width: 140px;
  position: relative;
}
.align b::after {
  content: ":";
  position: absolute;
  right: 10px;
}

.align2 b {
  display: inline-block;
  width: 216px;
  position: relative;
}
.align2 b::after {
  content: ":";
  position: absolute;
  right: 10px;
}
.empdetials {
  margin-bottom: 55px;
}
.align > span,
.align2 > span {
  color: red;
  font-weight: 500;
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .second_divission {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .align2 b {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .align b {
    display: inline-block;
    width: 180px;
    position: relative;
    padding-right: 10px;
  }
  .Employee_details {
    overflow-x: scroll;
  }
  .align,
  .align > div,
  .align2,
  .align2 > div {
    display: block;
  }
  .align b::after {
    content: ":";
    position: relative;
    left: 5px;
  }
  .align2 b::after {
    content: ":";
    position: relative;
    left: 5px;
  }
  .align > span,
  .align2 > span {
    color: red;
    font-weight: 500;
  }
  .align > div,
  .align2 > div {
    margin: 10px 0px;
  }
  .align,
  .align2 {
    padding: 3px;
  }
}
