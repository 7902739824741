.attendance_record {
  display: flex;
  gap: 60px;
  padding: 0px 0px 0px 20px;
  align-items: baseline;
}
.attendance_btn {
  display: flex;
  flex-direction: column;
  padding: 45px 0px 0px 0px;
  gap: 39px;
}
.attendance_inputs > div {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 20px;
}
.textarea {
  min-width: 267px;
  max-width: 267px;
  min-height: 112px;
  max-height: 112px;
}
.onleave {
  width: 126px;
}
.empcode {
  width: 131px;
}
.empcode_select {
  width: 300px;
}
.for_attendance_submit {
  display: flex;
  justify-content: end;
}
.attendance_heading {
  margin: 10px 20px;
  display: flex;
  /* justify-content: center; */
}
.clock__out__heading {
  margin: 10px;
  color: red;
}
.clocked_out {
  text-align: center;
}
.clouk_out_btn {
  display: flex;
  justify-content: flex-end;
  width: 82%;
  margin: 10px;
}
.back__clock__out {
  padding: 8px 20px;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 10px;
  color: #0a0a0a;
  border: none;
  position: absolute;
  top: 12px;
  right: 150px;
  z-index: 100;
}
.employee_tbody_tr {
  cursor: pointer;
}
.employee_tbody_tr:hover {
  background-color: rgb(235, 243, 249);
}
@media only screen and (max-width: 600px) {
  .attendance_record {
    display: flex;
    flex-direction: column;
  }
  .textarea {
    min-width: 215px;
    max-width: 215px;
    min-height: 112px;
    max-height: 112px;
  }
  .media_query_btn {
    text-align: center;
  }
  .clouk_out_btn {
    padding: 20px 0px 0px 270px;
    display: flex;
    justify-content: center;
  }

  .attendance_inputs > div {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
  }
  .attendance_inputs > .radio__align {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 20px;
    flex-direction: row;
  }
  .clock__out__heading {
    margin-top: 40px;
  }
  .back__clock__out {
    position: absolute;
    top: 14px;
    right: 100px;
    z-index: 100;
    font-size: 16px;
  }
}
