.sidebar {
  width: max-content;
    height: 108vh;
    background-color: #f5f9f9;
    padding: 5px;
    transition: transform 0.3s ease;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    margin-top: 60px;

  }
  
  .menu>Link {
    margin-bottom: 10px;
    width:20px
  }
  
  .menu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 35px;
    transition: color 0.3s ease;
  }
  
  .menu a:hover {
    color: #007bff;
  }
  
  .toggle-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .toggle-icon {
    font-size: 25px;
  }
  
  .open {
    transform: translateX(0%);
  }
  
  @media (max-width: 767px) {
    .sidebar {
      width: 100%;
    }
  
    
  }
  