.searchbar:hover {
  box-shadow: 1px 1px 8px 1px #dcdcdc;
  border: solid, 1px;
  border-style: solid;
  border-color: #c7c7fa;
}
.Search {
  min-height: 100vh;
  justify-content: center;
}
.card {
  border-radius: 8px;
  padding: 0px;
  display: flex;
  justify-content: center;
  gap: 20px;
  border-radius: 10px;
}
.emp_search {
  font-size: 20px;
}
.Card_item {
  display: grid;
  justify-content: right;
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 20px;
}
.card-content {
  margin-bottom: 16px;
}
.card-title {
  font-size: 18px;
}
.card-description {
  font-size: 16px;
  color: #555555;
}
.icons {
  display: flex;
  font-size: 134%;
  justify-content: end;
  gap: 30px;
  padding: 7px;
}
.Reset__btn {
  background-color: #002d62;
  color: #fff;
  font-weight: 600;
  padding: 15px 20px;
  border-radius: 5px;
}
.link {
  text-decoration: none;
}
.search__db {
  display: flex;
  justify-content: space-between;
}
.register_btn {
  border-radius: 2px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 3px;
  color: white;
}
.logout_btn {
  border-radius: 2px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;
  margin: 10px 0px;
  outline: none;
  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
  color: white;
  transition: 1s ease-in-out all;
}
.register_btn:hover {
  transform: scale(1.05);
  background-color: rgb(4, 36, 58);
}
.Application_name {
  /* text-align: center; */
  font-size: 2rem;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px 9px 2px 9px;
  /* text-decoration: underline;
    text-decoration-thickness: 2.3px;
    text-decoration-color: #07c;
 
    text-decoration-style: double  */
}
.background_color {
  background-color: #e8eafe;
  display: flex;
  justify-content: space-around;
  /* padding: 20px;s */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 11px 0px 9px 9px;
  width: 95%;
}
.bar__style {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.filter_options {
  justify-content: space-between;
}
.filter_options label {
  font-size: 20px;
  font-weight: 510;
}
.filter_options > div {
  display: flex;
  justify-content: inherit;
  margin: 0 0 24px 0;
}
.Datesfilter {
  justify-content: space-between;
}
.Datesfilter label {
  font-size: 20px;
  font-weight: 510;
  text-align: start;
}
.Datesfilter > div {
  display: flex;
  justify-content: inherit;
  margin: 0 0 24px 0;
}
.start_date {
  display: flex;
  gap: 61px;
}
.Various_search_btn {
  display: flex;
  padding: 10px 40px;
}
.Info_filters {
  height: 28px;
  /* border: none; */
  /* border-bottom: 1px solid; */
  border-radius: 3px;
  margin-left: 5px;
  border: 1px solid;
}
.Info_filters_years_of_exp {
  height: 28px;
  /* border: none; */
  /* border-bottom: 1px solid; */
  border-radius: 3px;
  margin-left: 5px;
  border: 1px solid;
  width: 130px;
}
.Various_search_btn {
  background-color: #002d62;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans",
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  outline: none;
  padding: 8px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

/* .Various_search_btn:hover,
.Various_search_btn:focus {
  background-color: #07c;
}

.Various_search_btn:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15); 
}

/* .Various_search_btn:active {
  background-color: #0064bd;
  box-shadow: none;
} */
.record__found {
  margin: 0px 0px 20px 0px;
  color: red;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .background_color {
    flex-direction: column;
  }

  .card {
    flex-direction: column;
    padding: 15px 20px;
    gap: 5px;
  }
  .Info_filters {
    margin-left: 0px;
    width: 170px;
  }
  .bar__style {
    margin-top: 2px;
  }
  .Info_filters_years_of_exp {
    width: 170px;
  }
  .Reset__btn {
    background-color: #002d62;
    color: #fff;
    font-weight: 600;
    padding: 12px 12px;
    border-radius: 5px;
  }
}
/* === HEADING STYLE #2 === */
.header2 {
  margin: 10px 10px 0px 20px;
  display: inline-block;
  font-family: "josefin sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  color: var(--bg-color);
  text-transform: uppercase;
}
.header2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: var(--bg-color);
}
.header2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;

  background-color: var(--bg-color);
}
.Viewe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  border: 1px solid gray;
  height: 30px;
  border-radius: 5px;
}
.Viewe > p:last-child {
  color: red;
}
