@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  /* background-color: #E1EBEE; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --text-color: #fff;
  --bg-color: #002d62;
  --font--weight: 500;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}
input[type="text"] {
  cursor: text;
}
input[type="date"] {
  cursor: pointer;
}
select {
  cursor: pointer;
}
input {
  border: 1px solid;
  border-radius: 5px;
}

@media only screen and (max-width: 660px) {
  button {
    pad: 5px 10px;
  }
}
